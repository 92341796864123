.item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #ececec;
  cursor: pointer;
  padding: 8px 16px;
  background: var(--tab-bg);
  border: 1px solid var(--tab-border);
}
.active {
  border-top: 1px solid var(--accent-color);
  background: var(--tab-active-bg);
  border-bottom: none;
}
.icon {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
