@media (min-width:992px) {
  .circlecontainer{
    margin-right:13rem;
  }
}
.circle {
  width: 20vw;
  height: 20vw;
  background-color: #fff;
  border-radius: 50%;
  transform: scale(0);
  animation: scale 1s both linear;
  margin: 2em auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.memoji {
  width: 100%;
  opacity: 0;
  animation: opacity 1s both linear;
  animation-delay: 1s;
  position: absolute;
  border-radius:50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circle_text_box {
  width: 100%;
  height: 100%;
  position: relative;
  transform-origin: center center;
  opacity: 0;
  animation: 
    rotate 40s infinite linear,
    opacity 1s both linear;
  animation-delay: 2s;
}
@keyframes scale {
  from { transform: scale(0); }
  to { transform: scale(1); }
}
@keyframes opacity {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
