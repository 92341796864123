.codeline {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.codeline::before {
  content: counter(line);
  counter-increment: line;
  margin-right: 1rem;
  color: grey;
  min-width: 26px;
}
@media screen and (max-width: 600px) {
  .codeline::before {
    min-width: 16px;
  }
}