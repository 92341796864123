.codeblock {
  counter-reset: line;
  font-size: 1.2rem;
  line-height: 1.7rem;
}
.codeblock a {
  color: var(--accent-color);
  text-decoration: none;
}
.codeblock a:hover {
  text-decoration: underline;
}
.className {
  color: var(--accent-color);
}
@media screen and (max-width: 600px) {
  .codeblock {
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
}
