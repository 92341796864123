@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap');

.explorer {
  width: 172px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #e1e4e8;
  background-color: var(--explorer-bg);
  border-right: 1px solid var(--explorer-border);
}
.icon {
  width: 16px;
  height: 16px;
}
.title {
  font-weight: 300;
  padding: 8px;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: inherit;
}
.heading {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #fff;
}
.heading .icon {
  transition: 0.3s;
}

.file {
  display: flex;
  align-items: center;
  justify-self: start;
  padding: 2px 8px;
  margin-bottom: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.file:hover {
  background-color: var(--explorer-hover-bg);
}
.file .icon {
  margin-right: 6px;
}

@media screen and (max-width: 600px) {
  .explorer {
    display: none;
  }
}