@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap');

.windowbar {
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  background-color: var(--titlebar-bg);
  font-size: 0.85rem;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: normal;
  border-bottom: 1px solid #191d20;
}
.window_btn_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: inherit;
}
.icon {
  width: 20px;
  margin-right: 8px;
}
.btn_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: inherit;
}
.btn_item {
  margin: 0 0.5rem;
  cursor: pointer;
}
.window_ic {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 6px;
  border-radius: 50%;
  cursor: pointer;
}
.window_ic__red {
  background-color: #EC6A5E;
}
.window_ic__yellow {
  background-color: #F4BE4F;

}
.window_ic__green {
  background-color: #61C555;
}

@media screen and (max-width: 900px) {
  .btn_items {
    display: none;
  }
}