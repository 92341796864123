.note {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 15em;
  padding: 2em 1.5em 2em 5em;
  color: #252525;
  background-color: var(--text-color);
  opacity: 1;
  background-size: 32px 32px;
  background-image:  repeating-linear-gradient(0deg, #e0e0e0, #e0e0e0 1.6px, #efefef 1.6px, #efefef);
  border-radius: 0 0.5em 0.5em 0;
}
.note:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}
.circle_wrap {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0;
  transform: translateX(-50%);
  background-color: transparent;
}
.circle_wrap::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  right: -3em;
  top: 0;
  background-color: var(--accent-color);
}
.circle {
  width: 1.5em;
  height: 1.5em;
  background-color: var(--main-bg);
  border-radius: 50%;
}
@media screen and (max-width: 720px) {
  .note {
    font-size: 0.85rem;
  }
}