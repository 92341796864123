@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700&display=swap');


.layout {
  display: flex;
  width: 100%;
  height: calc(100vh - 30px - 25px);
  background-color: var(--main-bg);
}
.main {
  width: calc(100% - 56px - 172px);
}
.content {
  overflow-y: auto;
  height: calc(100% - 52px);
  font-family: JetBrains Mono,'Nanum Gothic';
  color: var(--text-color);
  padding: 2rem;
}
.code_examples {
  height: 100%;
}
.content::-webkit-scrollbar {
  width: 12px;
}

.content::-webkit-scrollbar-track {
  background: var(--scrollbar-track-bg);
  border-left: 1px solid #1e1f29;
}
.content::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-bg);
  border-left: 1px solid #1e1f29;
}
@media screen and (max-width: 600px) {
  .main {
    width: calc(100% - 56px);
  }
  .content {
    padding: 1rem;
    overflow-y: auto;
  }
}