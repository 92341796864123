.card {
    width: 100%;
    background-color: var(--article-bg);
    overflow: hidden;
    border-radius: 0.5em;
  }
  .card:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  }
  .img_wrap {
    position: relative;
    overflow: hidden;
  }
  .copy_tag {
    position: absolute;
    width: 150%;
    left: 13%;
    top: 19%;
    padding: 0.3em 0;
    text-align: center;
    white-space: nowrap;
    background-color: var(--accent-color);
    transform: translate(-50%,-50%) rotate(-43deg);
  }
  .card_img {
    width: 100%;
    max-height: 260px;
    object-fit: contain;
  }
  .card_content {
    height: 230px;
    padding: 1em;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .card_title {
    margin-bottom: 0.5em;
    font-size: 1.1em;
  }
  .card_desc {
    min-height: 2.8em;
    margin-bottom: 0.5em;
    font-size: 0.9em;
  }
  .skill_tag {
    display: inline-block;
    margin: 0.5em 0.5em 0 0;
    font-size: 0.9em;
  }
  .memo {
    display: block;
    margin-top: 1em;
    padding: 0.3em;
    text-align: center;
    border: 2px solid #00ad00;;
    border-radius: 1em;
    transition: 0.5s;
  }
  .memo:hover {
    background-color: #00ad00;;
  }
  .link_wrap {
    width: 100%;
    padding-bottom: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
  .link_wrap a {
    margin-top: 1em;
    padding: 0.3em;
    text-align: center;
    border-radius: 1em;
    transition: 0.5s;
    width: calc((100% - 1em) / 2);
  }
  .demo {
    border: 2px solid var(--accent-color);
  }
  .demo:hover {
    background-color: var(--accent-color);
  }
  @media screen and (max-width: 720px) {
    .card {
      font-size: 0.85rem;
    }
  }