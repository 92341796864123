.grid_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5em;
}
.projects_title {
  margin-bottom: 0.8em;
}
@media screen and (max-width: 960px) {
  .grid_container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 720px) {
  .grid_container {
    grid-template-columns: repeat(1, 1fr);
  }
}