/* Global styles */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body {
  height: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}